import { Box, CircularProgress, Typography } from "@mui/material";
import axios from "axios";

// const BASE_URL = 'https://jsonplaceholder.typicode.com';
// const BASE_URL = "http://192.168.1.121:4000/api";
//const BASE_URL = "https://liplbackend.luknos.com/api";
// const BASE_URL = "https://luknosbackend.luknos.com/";
const BASE_URL = "http://localhost:5000";



const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    // You can add other headers like authorization token here
  },
});

// Define common API methods
const _get = (url, config = {}) => {
  return apiClient.get(url, config);
};

const _delete = (url, config = {}) => {
  return apiClient.delete(url, config);
};

const _put = (url, data = {}, config = {}) => {
  return apiClient.put(url, data, config);
};

const _post = (url, data = {}, config = {}) => {
  return apiClient.post(url, data, config);
};

// Loader function
const _getloader = () => {
  return (
    <Box>
      <CircularProgress size="large" color="#138B7F" />
      <Typography>Loading...</Typography>
    </Box>
  );
};

// Export API methods
export { _get, _delete, _put, _post, _getloader };
