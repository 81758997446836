import React, { useState } from "react";
import axios from "axios";
import "./chatbot.css";
import { _post } from "../Config/axiosInstance";

const Chatbot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    const sendMessage = async () => {
        if (!input.trim()) return; // Don't send empty messages

        const newMessage = { role: "user", content: input };
        setMessages((prevMessages) => [...prevMessages, newMessage]); // Add user message

        try {
            const res = await axios.post("http://localhost:5000/chat", { message: input });
            const botMessage = { role: "assistant", content: res.data.choices[0].message.content };
            setMessages((prevMessages) => [...prevMessages, botMessage]); // Add bot response
        } catch (error) {
            console.error("Error:", error);
        }

        setInput(""); // Clear the input field
    };

    return (
        <div className="chatbot-wrapper">
            {/* Floating Chatbot Icon */}
            {!isOpen && (
                <div className="chatbot-icon" onClick={toggleChat}>
                    💬
                </div>
            )}

            {/* Chat Window */}
            {isOpen && (
                <div className="chatbot-container">
                    <div className="chat-header">
                        <span>Chatbot</span>
                        <button className="close-button" onClick={toggleChat}>✖</button>
                    </div>
                    <div className="chat-window">
                        {messages.map((msg, index) => (
                            <div key={index} className={`message ${msg.role}-message`}>
                                <div className="message-content">{msg.content}</div>
                            </div>
                        ))}
                    </div>
                    <div className="input-container">
                        <input
                            type="text"
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={(e) => e.key === "Enter" && sendMessage()} // Send message on Enter key
                            className="input-box"
                            placeholder="Type a message..."
                        />
                        <button onClick={sendMessage} className="send-button">Send</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chatbot;