import { useState } from "react";
import axios from "axios";
import "./callcomponent.css";

const CallComponent = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const makeCall = async () => {
    await axios.post(
      "https://webhookdemo-5len.onrender.com/make-outbound-call",
      {
        phoneNumber,
      }
    );
    console.log("first", { phoneNumber });
    alert("Call Initiated!");
  };

  return (
    <div className="call-wrapper">
    {!isOpen && (
         <div className="call-icon" onClick={() => setIsOpen(!isOpen)}>📞</div>
        )}
   
    {/* Call UI Popup */}
    {isOpen && (
    <div className="callbody">
         <div className="call-header">
                        <span>AI Voice Call Assistent</span>
                        <button className="call-close-button" onClick={() => setIsOpen(false)}>✖</button>
                    </div>
        
        <div className="input-call-container">
     
      <input
      className="input-call-box"
        type="text"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="Enter phone number"
      />
      <button className="send-button" onClick={makeCall}>Make Call</button>
      </div>
    </div>
     )}
    </div>
  );
};

export default CallComponent;